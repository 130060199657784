@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

:root {
    --black: #000;
    --grey: #ddd;
    --font-grey: #717171;
    --white: #ffffff;
    --theme: #7B68EE;
    --light-grey: #a0a0a0;
  }
  
body {
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  