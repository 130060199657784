/* Global Styles */

.main {
  overflow: hidden;
  font-family: Arial, sans-serif;
  margin: 0;
}

.bg-page {
  background-color: #f8f9fa;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
}

/* Navbar */
.navbar {
  margin: 0;
  padding: 0rem 0.3rem;
}

.logo, .logo-x {
  display: flex;
  align-items: center;
  height: 80px;
  width: 250px;
  cursor: pointer;
  margin-top: 0;
}

.menu-items {
  padding: 0.5rem 1rem !important;
}
.navbar-blurred {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.8);
}
.menu-link {
  text-decoration: none;
  color: inherit;
}

.nav-item .nav-link, .nav-item .nav-link-x {
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-item.active .nav-link, .nav-item.active .nav-link-x {
  color: #93278F;
  transform: scale(1.1);
  font-weight: bold;
}

.blurred {
  backdrop-filter: blur(5px);
}


/* Carousel */
.carousel-item img {
  object-fit: cover;
  height: 100%;
}
.carousel-inner {
  position: relative;
  overflow: hidden;
}

.carousel-item img {
  transition: transform 0.5s ease-in-out;
}

.carousel-item img:hover {
  transform: scale(1.05);
}

.carousel-caption {
  background: linear-gradient(
    145deg, /* Diagonal gradient direction */
    rgba(147, 39, 143, 0.8), /* Primary color (#93278F) with opacity */
    rgba(255, 255, 255, 0.8) 160% /* White with opacity for contrast */
  );
  padding: 1rem;
  border-radius: 0.5rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators .active {
  background-color: rgba(255, 255, 255, 1);
}


/* About */
.about-title {
  font-weight: 700;
}


.about-subtitle {
  font-weight: 300;
}

.about-image {
  border-radius: .375rem;
}


.about-text {
  margin-top: 20px;
}

.icon {
  font-size: 2rem;
}

.cta-x{
  background-color: #93278F;
}

.jumbotron-section {
  margin-top: 50px;
}

.jumbotron-wrapper {
  padding: 50px;
  border-radius: .375rem;
}

.jumbotron-icon {
  color:#93278F;
}

.jumbotron-title {
  font-weight: 700;
}

.jumbotron-text {
  font-weight: 300;
}

.jumbotron-btn, .jumbotron-btn-secondary {
  font-weight: 500;
}

/* Why Us */
.text-x{
  color: #93278F;
}

/* Features */
.bg-x{
  background-color: #93278F;
}

/*FAQ*/
.accordion-button {
  transition: background-color 0.3s ease;
  color: #93278F; /* Primary text color */
  font-weight: 500; /* Slightly bolder for better visibility */
}

.accordion-button:hover {
  background-color: rgba(147, 39, 143, 0.1); /* Lighten primary color on hover */
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 39, 143, 0.5); /* Focus effect */
}

.accordion-item + .accordion-item {
  border-top: 1px solid #dee2e6; /* Divider line */
}

.accordion-body {
  color: #555; /* Set a darker color for body text for better readability */
}
.accordion-button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background-color: #333; /* Darker shade of your primary color */
  color: #ffffff;
}

.accordion-body {
  border-radius: 0 0 0.5rem 0.5rem; /* Rounded corners for the body */
}



/* Footer */
.footer {
  background-color: #EAEAEA;
}
.footer li a {
  color: inherit;
  text-decoration: none;
}
.footer li a:hover {
  color: #93278F;
}
#social-icons li {
  width: 70px; 
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
}

#social-icons a {
  color: black;
  display: inline-block;
  font-size: 5rem;
  transition: transform 0.5s; 
}

#social-icons li:hover a{
  color: #93278F;
  transform: scale(1.1); 
}

#social-icons {
  display: flex;
  flex-direction: row;
}
#footer-subscribe-div input[type="email"] {
  padding: 10px;
  border: 1px solid #93278F;
  border-radius: 4px;
}

#footer-subscribe-div input:focus {
  border-color: #93278F;
}

#footer-subscribe-div button {
  color: #93278F;
  border: 1px solid #93278F;
  width: 150px;
}

#footer-subscribe-div button:hover {
  background: white;
  color: black;
  font-weight: bold;
  border: 2px solid #93278F;
}
#footer-quick-links ul li {
  padding-bottom: 10px;
  font-size: 18px;
}

/*Testimonies */
#test-content {
  height: 400px;

}

/*Services*/
.listing-grid {
  padding: 0; /* Remove padding from the container */
}

.listing-grid .row {
  margin: 0; /* Remove margin from the row */
}

.listing-grid .col {
  padding: 0; /* Remove padding from columns */
}
.card {
  margin-bottom: 30px; /* Optional: Remove bottom margin from cards */

}
.listing-link {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color from parent */
}

.listing-link .card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.listing-link .card:hover {
  transform: scale(1.02); /* Slightly zoom in on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  cursor: pointer;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border-bottom: 1px solid #dee2e6;
  padding: 20px 0 20px 0;
 /* background: url('./images/hero-bg.jpg') no-repeat center center/cover;*/
}


.search-bar {
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 70%;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: none;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.search-bar {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-bar .form-control.rounded-input {
  width: 50%;
  border-radius: 25px;
  border: 1px solid #ced4da;
}

.listing-grid {
  padding: 50px 0;
}

/* FilterNav.css */

.filter-nav {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  background-color: #f8f9fa;
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  color: #6c757d;
  transition: color 0.3s ease;
}

.filter-item:hover,
.filter-item.active {
  color:black;
  font-weight: bold;
  border-bottom: 1px solid black;
}


.filter-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  margin-bottom: 5px;
}

.filter-title {
  font-size: 14px;
}

/* Modal*/
.modal-content {
  border-radius: 1rem;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  padding: 2rem;
}

/*Services*/
.service-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.service-card.grid .card-img-top {
  height: 200px;
  object-fit: cover;
}

.service-card.list .card-img-top {
  height: 100px;
  object-fit: contain;
}

.lazyload {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.lazyload[loading="lazy"] {
  opacity: 1;
}

/* Adjust grid layout for smaller screens */
@media (max-width: 768px) {
  .service-card.grid .card-img-top {
      height: 150px;
  }

  .service-card.list .card-img-top {
      height: 80px;
  }

  .service-card {
      margin-bottom: 1rem;
  }
}

/* Responsive design for price range filter */
@media (max-width: 576px) {
  .price-range {
      flex-direction: column;
      align-items: stretch;
  }

  .price-range input {
      width: 100%;
  }
}
